@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Pretendard", sans-serif;
  }
}

@layer components {
  .resizer {
    display: inline-block;
    width: 10px; /* 리사이저 너비 */
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: col-resize;
    z-index: 1; /* 리사이저가 헤더 셀 위에 있도록 */
  }

  .isResizing {
    background-color: gray; /* 리사이징 중인 경우 색상 변경 */
  }
}
