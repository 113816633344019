.modal_input {
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 20px 24px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid #80befc;
}

.modal_notice {
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 20px 24px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid #80befc;
}

.common-button {
  height: 48px;
  border-radius: 10px;
  font-size: 18px;
  width: 200px;
}

.cancel-button {
  border: 1px solid #e5e5e5; /* border-gray-200 */
  background-color: white;
  color: #8b8b8b; /* text-gray8b */
  margin-left: 41px;
}

.cancel-button-email {
  border: 1px solid #e5e5e5; /* border-gray-200 */
  background-color: white;
  color: #8b8b8b; /* text-gray8b */
  margin-left: 38px;
}
.confirm-button-email {
  margin-right: 38px;
}

.confirm-button {
  margin-right: 48px;
}

.confirm-button-single {
  border: 1px solid #e5e5e5; /* border-gray-200 */
  background-color: #007bff;
  color: white;
}
/* 활성화된 확인 버튼 */
.confirm-button-enabled {
  background-color: #007bff; /* bg-primary-500 */
  color: white;
}

/* 비활성화된 확인 버튼 */
.confirm-button-disabled {
  background-color: #f5f5f5; /* bg-gray-100 */
  color: #d1d1d1; /* text-gray-300 */
}

/* 부모 flex 컨테이너 */
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  padding-top: 40px;
  padding-bottom: 42px;
}
.button-container-inquiry {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  padding-top: 40px;
  padding-bottom: 40px;
}
