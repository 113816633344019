.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px; /* Width of the spinner */
  height: 30px; /* Height of the spinner */
  animation: spin 1s linear infinite; /* Spin animation */
  margin: 14.5px auto; /* Center the spinner */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
